import * as React from 'react';
import { Card } from 'primereact/card';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/pro-light-svg-icons';

export default function FlagSummary({ flag }) {
    const navigate = useNavigate();

    const header = (
        <div className="flex-row end" style={{ margin: '10px 20px 0 20px'}}>
            <div className="pointer" onClick={() => navigate('/flags/' + flag.flagId)} ><span>View Details </span><FontAwesomeIcon icon={faArrowCircleRight}/></div>
        </div>
    )

    return (
        <Card style={{ margin: 10, width: '550px' }} title={flag.name} header={header}>
            <div className="flex-col">
                <div className="tertiary-title">
                    {flag.flagId}
                </div>
                <div className="flex-col">
                    {flag.project && <div className="p10"><b>Project:</b> {flag.project}</div>}
                    {flag.environment && <div className="p10"><b>Environment:</b> {flag.environment}</div>}
                    <div className="flex-row">
                        <div className="p10"><b>Target:</b></div>
                        <div className="p10">{flag.percentage}%</div>
                    </div>
                    <div className="flex-row">
                        <div className="p10"><b>Audience:</b></div>
                        <div className="p10">{(flag.requests * 100).toFixed(2)}%</div>
                    </div>
                </div>
            </div>
        </Card>
    )
}