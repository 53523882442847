import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { useAxios } from '../../utils/hooks.ts';
import { Button } from 'primereact/button';
import { confirmPopup } from 'primereact/confirmpopup';
import { Tooltip } from 'primereact/tooltip';
import { OverlayPanel } from 'primereact/overlaypanel';
import CreateEditEnvironment from './CreateEditEnvironment';

export default function EnvironmentOptions({ environment, displayToast, flipFetchFlag, toast, role }) {
    const [ editEnvironmentVisible, setEditEnvironmentVisible ] = React.useState(false);
    const axiosInstance = useAxios();
    const op = React.useRef(null);

    const toggleEditEnvironmentVisible = () => {
        setEditEnvironmentVisible(!editEnvironmentVisible);
    }

    const reject = () => {
    };

    const deleteEnvironment = () => {
        axiosInstance.current.delete("/projects/environment/" + environment.environmentId)
            .then(() => {
                flipFetchFlag();
            })
            .catch(() => {
                toast.current.show({severity: 'error', summary: 'Error!', details: 'Unable to delete environment'});
            })
    };

    const confirmDeleteEnvironment = (e) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete this environment?",
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: deleteEnvironment,
            reject
        })
    }

    return (
        <>
        {(role === 'ADMIN' || role === 'OWNER') ?
        <>
        <CreateEditEnvironment visible={editEnvironmentVisible} hide={toggleEditEnvironmentVisible} displayToast={displayToast} environment={environment} />
        <Tooltip target=".options-wrapper" mouseTrack mouseTrackLeft={10} />
        <div className="title options-wrapper pl30" onClick={(e) => op.current.toggle(e)} data-pr-tooltip="Environment Options">
            <FontAwesomeIcon icon={faEllipsisH} />
        </div>
        <OverlayPanel ref={op} id="options-overlay" className="options-overlaypane">
            <div className="flex-col start">
                <Button label="Edit" className="p-button-text p-button-plain" onClick={toggleEditEnvironmentVisible}/>
                <Button label="Delete" className="p-button-text p-button-plain" onClick={confirmDeleteEnvironment} />
            </div>
        </OverlayPanel>
        </>
        :
        <></>
        }
        </>
    )
}